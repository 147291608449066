import { makeStyles } from 'tss-react/mui'
import { LogoImage } from './LogoImage'

const useStyles = makeStyles()((theme) => ({
  logoWrapper: {
    display: 'flex',
    alignItems: 'end',
  },
  partnerName: {
    marginLeft: '8px',
    paddingBottom: '8px',
    maxWidth: '120px',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.text.secondary,
    fontSize: '11px',
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
}))

export const Logo = ({ partnerName = null }) => {
  const { classes } = useStyles()

  return (
    <div className={classes.logoWrapper}>
      <LogoImage />
      {partnerName && <span className={classes.partnerName}>{partnerName}</span>}
    </div>
  )
}
