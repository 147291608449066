import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { gql, useApolloClient, useMutation, useQuery, useSubscription } from '@apollo/client'
import produce from 'immer'
import { currentUserQuery } from '../auth/authQueries'
import { useCurrentOrganization } from '../hooks/useCurrentOrganization'
import { useCurrentProperty } from '../hooks/useCurrentProperty'
import { PROPERTY_VIEW } from '../property/propertyQueries'
import { ORGANIZATION_LIST_VIEW } from '../user/userQueries'
import { createArrayUpdater, useNotifications } from '../util'

const ORGANIZATION_MEMBERS_PAGE_VIEW = gql`
  fragment OrganizationMembersPageView on Organization {
    id
    slug
    properties {
      nodes {
        id
        slug
        environments {
          nodes {
            id
            name
          }
        }
      }
    }
    members {
      role
      propertyRoles {
        id
        role
        property {
          id
          slug
        }
      }
      environmentRoles {
        id
        role
        environment {
          id
          name
          property {
            id
          }
        }
      }
      user {
        id
        fullname
        avatarUrl
        email
        confirmedAt
        lastSignInAt
      }
    }
  }
`

export const ORGANIZATION_VIEW = gql`
  fragment OrganizationView on Organization {
    id
    gid
    name
    slug
    tier
    mtlsEnabled
    edgeFunctionsEnabled
    wafHexId
    personal
    createdAt
    disabledAt
    xdnRegion {
      id
      name
      primaryAwsRegion
      secondaryAwsRegion
    }
    edgeXdnRegion {
      id
      name
    }
    partner {
      id
      name
      slug
      logoUrl
      logoUrlDark
      supportInfo
    }

    awsAccount {
      id
      maxConcurrency
      signInUrl
      provisionerVersion
    }
    limitMaxEnvironments
    limitMaxEnvironmentsDefault
    asmEnabled
    asmShowByDefault
    asmSeedValidation
    asmCollectionLimit
    asmAssetLimit
    asmEnableWafDetection
    asmExposureDueDayCount
    asmAllowEdgioIps
    idsTenantId
    idsOrganizationFeatures
    trustedDomains
    ipAllowList
    pciCompliance
    pciDnsDomainName
    nonPciDnsDomainName
    note
    members {
      user {
        id
        email
        confirmedAt
        staff
      }
    }
  }
`

const createOrganizationMutation = gql`
  mutation createOrganization($name: String!, $partnerId: ID) {
    createOrganization(organization: { name: $name, partnerId: $partnerId }) {
      organization {
        ...OrganizationListView
      }
      userErrors {
        path
        message
      }
    }
  }
  ${ORGANIZATION_LIST_VIEW}
`

export const useCreateOrganizationMutation = () => {
  const [mutate, mutationResult] = useMutation(createOrganizationMutation)

  return [
    async (variables) => {
      const { data } = await mutate({
        variables,
        update: (
          cache,
          {
            data: {
              createOrganization: { organization },
            },
          },
        ) => {
          if (organization) {
            cache.updateQuery(
              { query: currentUserQuery },
              produce((draft) => {
                if (draft) {
                  draft.currentUser.members.nodes.push({
                    __typename: 'Member',
                    organization,
                    role: 'super_admin',
                  })
                }
              }),
            )
          }
        },
      })
      return data.createOrganization
    },
    mutationResult,
  ]
}

export const getOrganization = gql`
  query organization($slug: String!) {
    organization(slug: $slug) {
      ...OrganizationView
    }
    organizationFeatures(slug: $slug) {
      name
      minXdnVersion
      minSubaccountVersion
    }
    currentActorOrganizationRole(slug: $slug) {
      actions {
        action
        subject
      }
      role
    }
  }
  ${ORGANIZATION_VIEW}
`

export const getOrganizationMembersPage = gql`
  query organization($slug: String!) {
    organization(slug: $slug) {
      ...OrganizationMembersPageView
    }
  }
  ${ORGANIZATION_MEMBERS_PAGE_VIEW}
`

export const updateOrganization = gql`
  mutation updateOrganization($organization: UpdateOrganizationAttributes!) {
    updateOrganization(organization: $organization) {
      organization {
        ...OrganizationView
      }
      userErrors {
        path
        message
      }
    }
  }
  ${ORGANIZATION_VIEW}
`

export const updateOrganizationAsmSettings = gql`
  mutation updateOrganizationAsmSettings($organization: UpdateOrganizationAsmAttributes!) {
    updateOrganizationAsmSettings(organization: $organization) {
      organization {
        ...OrganizationView
      }
      userErrors {
        path
        message
      }
    }
  }
  ${ORGANIZATION_VIEW}
`

export const useUpdateOrganizationMutation = () => {
  const [mutate, mutationResult] = useMutation(updateOrganization)

  return [
    async (variables) => {
      const { data } = await mutate({
        variables,
      })
      return data.updateOrganization
    },
    mutationResult,
  ]
}

const deleteOrganization = gql`
  mutation deleteOrganization($id: ID!) {
    deleteOrganizationWithErrors(id: $id) {
      userErrors {
        message
      }
    }
  }
`

export const useDeleteOrganizationMutation = () => {
  const [mutate, mutationResult] = useMutation(deleteOrganization)

  return [
    async (variables) => {
      const { data } = await mutate({
        variables,
      })
      return data.deleteOrganizationWithErrors
    },
    mutationResult,
  ]
}

export const deleteOrganizationMembers = gql`
  mutation deleteOrganizationMembers($userIds: [ID!]!, $organizationId: ID!) {
    deleteOrganizationMembers(userIds: $userIds, organizationId: $organizationId) {
      ...OrganizationView
    }
  }
  ${ORGANIZATION_VIEW}
`

export const useDeleteOrganizationMembersMutation = () => {
  const [mutate, mutationResult] = useMutation(deleteOrganizationMembers)

  return [
    async (variables) => {
      const { data } = await mutate({
        variables,
      })
      return data.deleteOrganizationMembers
    },
    mutationResult,
  ]
}

export const updateOrganizationMemberRole = gql`
  mutation updateOrganizationMemberRole(
    $userId: ID!
    $organizationId: ID!
    $role: OrganizationRoleEnum
    $propertyRoles: [PropertyRoleArgument!]
    $environmentRoles: [EnvironmentRoleArgument!]
  ) {
    changeRole(
      userId: $userId
      organizationId: $organizationId
      role: $role
      propertyRoles: $propertyRoles
      environmentRoles: $environmentRoles
    ) {
      member {
        id
        role
      }
    }
  }
`

export const useUpdateOrganizationMemberRoleMutation = () => {
  const [mutate, mutationResult] = useMutation(updateOrganizationMemberRole)

  return [
    async (variables, organizationSlug) => {
      const { data } = await mutate({
        variables,
        refetchQueries: [
          { query: getOrganization, variables: { slug: organizationSlug } },
          { query: getOrganizationMembersPage, variables: { slug: organizationSlug } },
        ],
      })
      return data.changeRole
    },
    mutationResult,
  ]
}

const moveOrganization = gql`
  mutation moveOrganization($organizationId: ID!, $partnerId: ID) {
    moveOrganization(organizationId: $organizationId, partnerId: $partnerId)
  }
`

export const useMoveOrganizationMutation = () => {
  const [mutate] = useMutation(moveOrganization)

  return (organizationId, partnerId) => mutate({ variables: { organizationId, partnerId } })
}

export const inviteOrganizationMember = gql`
  mutation addOrganizationMembers(
    $members: [InviteOrganizationMembersInput!]!
    $organizationId: ID!
  ) {
    inviteOrganizationMembers(members: $members, organizationId: $organizationId) {
      organization {
        ...OrganizationView
      }
      userErrors {
        path
        message
      }
    }
  }
  ${ORGANIZATION_VIEW}
`

export const useInviteOrganizationMemberMutation = () => {
  const [mutate, mutationResult] = useMutation(inviteOrganizationMember)

  return [
    async (variables, organizationSlug) => {
      const { data } = await mutate({
        variables,
        refetchQueries: [
          { query: getOrganization, variables: { slug: organizationSlug } },
          { query: getOrganizationMembersPage, variables: { slug: organizationSlug } },
        ],
      })
      return data
    },
    mutationResult,
  ]
}

export const propertiesSubscription = gql`
  subscription getOrganizationPropertiesSubscription($organizationId: ID!) {
    organizationPropertiesUpdated(organizationId: $organizationId) {
      new {
        ...PropertyView
      }
      updated {
        ...PropertyView
      }
      deleted
    }
  }
  ${PROPERTY_VIEW}
`

const organizationDeletedSubscription = gql`
  subscription getDeletedOrganizationSubscription($organizationId: ID!) {
    organizationDeleted(organizationId: $organizationId) {
      deleted
    }
  }
`

export const getOrganizationPropertiesQuery = gql`
  query organizationProperties(
    $slug: String!
    $first: Int = 20
    $offset: Int = 0
    $orderBy: OrderByProperty = { slug: asc }
    $search: String
  ) {
    properties(
      organizationSlug: $slug
      first: $first
      offset: $offset
      orderBy: [$orderBy]
      search: $search
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ...PropertyView
      }
      recordCount
      pageCount
    }
  }
  ${PROPERTY_VIEW}
`

const organizationUpdatedSubscription = gql`
  subscription organizationUpdatedSubscription($organizationId: ID!) {
    organizationUpdated(organizationId: $organizationId) {
      new {
        ...OrganizationView
      }
      updated {
        ...OrganizationView
      }
    }
  }
  ${ORGANIZATION_VIEW}
`

export const useGetOrganizationPropertiesQuery = ({
  organizationSlug,
  offset = 0,
  first = 20,
  orderBy = 'slug',
  orderDirection = 'asc',
  search = '',
  fetchPolicy = 'cache-and-network',
}) => {
  const router = useRouter()
  const { data, previousData, loading, refetch, networkStatus, fetchMore, subscribeToMore } =
    useQuery(getOrganizationPropertiesQuery, {
      variables: {
        slug: organizationSlug,
        first,
        offset: offset,
        orderBy: { [orderBy]: orderDirection },
        search,
      },
      skip: !organizationSlug,
      notifyOnNetworkStatusChange: true,
      fetchPolicy,
    })
  const notification = useNotifications()
  const { currentOrganization } = useCurrentOrganization()
  const { currentProperty } = useCurrentProperty()

  useEffect(() => {
    if (!currentOrganization?.id) return
    const subscription = subscribeToMore({
      document: propertiesSubscription,
      variables: { organizationId: currentOrganization.id },
      updateQuery: (store, { subscriptionData }) => {
        const result = subscriptionData.data.organizationPropertiesUpdated
        // We refetch log entries if property was deleted
        // Log entries are cleaned up by db itself and not tracked by subscriptions
        if (result.deleted) {
          // If any user is on deleted property, he will be redirected to organization
          if (currentProperty?.id === result.deleted[0]) {
            router.push(`/${organizationSlug}`)
            notification.info(`${currentProperty?.slug} was deleted`, { autoHideDuration: 10000 })
          }
        }
        return createArrayUpdater(
          (prev) => prev.properties,
          ({ subscriptionData: { data } }) => data.organizationPropertiesUpdated,
        )(store, { subscriptionData })
      },
    })
    return subscription
  }, [])

  return {
    properties: data?.properties?.nodes ?? [],
    totalCount: data?.properties?.recordCount ?? previousData?.properties?.recordCount,
    loading,
    refetch,
    networkStatus,
    fetchMore,
    previousData,
  }
}

export const useGetOrganizationMembersQuery = (organizationSlug) => {
  const { data, loading } = useQuery(getOrganizationMembersPage, {
    variables: { slug: organizationSlug },
    skip: !organizationSlug,
  })

  const organization = data && data.organization

  return {
    organization,
    loading,
  }
}

export const useOrganizationDeletedSubscription = (organization) => {
  const { data } = useSubscription(organizationDeletedSubscription, {
    variables: { organizationId: organization?.id },
    skip: !organization?.id,
  })

  const { push } = useRouter()
  const notification = useNotifications()
  const client = useApolloClient()

  useEffect(() => {
    if (data?.organizationDeleted?.deleted) {
      client.cache.updateQuery(
        { query: currentUserQuery },
        produce((draft) => {
          draft.currentUser.members.nodes = draft.currentUser.members.nodes.filter(
            (node) => node.organization.id !== organization.id,
          )
          draft.currentUser.partnerMembers.nodes.forEach((node) => {
            node.partner.organizations.nodes = node.partner.organizations.nodes.filter(
              (orgNode) => orgNode.id !== organization.id,
            )
          })
        }),
      )
      push('/')
      notification.info(`${organization.name} was deleted`, { autoHideDuration: 10000 })
    }
  }, [data?.organizationDeleted?.deleted])
}

export const useGetOrganizationQuery = (organizationSlug, options = {}) => {
  const { refetch, subscribeToMore, data, loading, error } = useQuery(getOrganization, {
    variables: {
      slug: organizationSlug,
    },
    // Loading state would stay false on slug change otherwise
    notifyOnNetworkStatusChange: true,
    ...options,
  })

  const organization = data && data.organization
  const organizationFeatures = (data && data.organizationFeatures) || []
  const currentActorOrganizationRole = (data && data.currentActorOrganizationRole) || []

  useEffect(() => {
    if (organization) {
      const cleanups = [
        subscribeToMore({
          document: organizationUpdatedSubscription,
          variables: { organizationId: organization.id },
        }),
      ]
      return () => cleanups.forEach((cleanup) => cleanup())
    }
  }, [organization && organization.id])

  return {
    refetch,
    organization,
    loading,
    error,
    organizationFeatures,
    currentActorOrganizationRole,
  }
}

const getFastlyDomains = gql`
  query fastlyDomains($organizationId: ID!) {
    fastlyDomains(organizationId: $organizationId) {
      id
      name
      environment {
        id
        name
      }
      property {
        id
        slug
      }
    }
  }
`

const changeOrganizationNoteMutation = gql`
  mutation changeOrganizationNote($note: String, $organizationId: ID!) {
    changeOrganizationNote(note: $note, organizationId: $organizationId) {
      userErrors {
        path
        message
      }
    }
  }
`

export const useChangeOrganizationNoteMutation = () => {
  const [mutate, mutationResult] = useMutation(changeOrganizationNoteMutation)

  return [
    async (variables) => {
      const { data } = await mutate({
        variables,
      })
      return data
    },
    mutationResult,
  ]
}

export const useGetFastlyDeprecatedDomains = () => {
  const { currentOrganization } = useCurrentOrganization()
  const organizationId = currentOrganization?.id

  const { data } = useQuery(getFastlyDomains, {
    variables: { organizationId },
    skip: !organizationId,
  })

  // Fastly domains for BYOF are not deprecated
  if (currentOrganization?.byof) {
    return []
  }

  return data?.fastlyDomains || []
}

const idsOrganizationFeaturesQuery = gql`
  query idsOrganizationFeatures($organizationId: ID!) {
    idsOrganizationFeatures(organizationId: $organizationId)
  }
`
export const useIdsOrganizationFeaturesQuery = (organizationId) =>
  useQuery(idsOrganizationFeaturesQuery, { variables: { organizationId } })

export const updateOrganizationTier = gql`
  mutation updateOrganizationTier(
    $organizationId: ID!
    $idsOrganizationFeatures: JSON!
    $tier: OrganizationTierEnum!
    $mtlsEnabled: Boolean!
    $edgeFunctionsEnabled: Boolean!
  ) {
    updateOrganizationTier(
      organizationId: $organizationId
      idsOrganizationFeatures: $idsOrganizationFeatures
      tier: $tier
      mtlsEnabled: $mtlsEnabled
      edgeFunctionsEnabled: $edgeFunctionsEnabled
    ) {
      success
      userErrors {
        message
      }
    }
  }
`

export const useUpdateOrganizationTierMutation = () => {
  const [mutate] = useMutation(updateOrganizationTier)

  return async ({
    organizationId,
    tier,
    idsOrganizationFeatures,
    mtlsEnabled,
    edgeFunctionsEnabled,
  }) => {
    const { data } = await mutate({
      variables: {
        organizationId,
        idsOrganizationFeatures,
        tier,
        mtlsEnabled,
        edgeFunctionsEnabled,
      },
      update(cache, result) {
        if (result?.data?.updateOrganizationTier?.success) {
          const cacheId = cache.identify({ __typename: 'Query' })
          cache.modify({
            id: cacheId,
            fields: {
              adminOrganizationTier() {
                return [...idsOrganizationFeatures]
              },
            },
          })
        }
      },
    })
    return data.updateOrganizationTier
  }
}
